export const educationData = [
    {
        id: 1,
        institution: 'Sanothimi Campus, Tribhuvan University',
        course: 'MASTER IN INFORMATION AND COMMUNICATION TECHNOLOGY(ICTE)',
        startYear: '2016',
        endYear: '2019'
    },
    {
        id: 2,
        institution: 'Sanothimi Campus,, Tribhuvan University',
        course: 'BACHELOR IN INFORMATION AND COMMUNICATION TECHNOLOGY(ICTE)',
        startYear: '2012',
        endYear: '2015'
    },
    // {
    //     id: 3,
    //     institution: 'Masai School of Coding',
    //     course: 'Fullstack Web-Development',
    //     startYear: '2021',
    //     endYear: 'Present'
    // },
]