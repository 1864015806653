export const experienceData = [
    {
        id: 1,
        company: 'IT Consultant',
        jobtitle: 'Zoological Society of London (ZSL)',
        startYear: 'June-2023',
        endYear: 'Present',
        link:''
    },
    {
        id: 2,
        company: 'HeroBraces',
        jobtitle: 'FULLSTACK DEVELOPER',
        startYear: 'July-2021',
        endYear: 'Present',
        link:'https://app.goherogo.com'
    },
    {
        id: 3,
        company: 'Cloudyfox Technology',
        jobtitle: 'SENIOR SOFTWARE DEVELOPER/TEAM LEAD',
        startYear: 'July-2018',
        endYear: 'September-2022',
        link:'https://app.goherogo.com'
    },
    {
        id: 4,
        company: 'White rabbit studio',
        jobtitle: 'SOFTWARE DEVELOPER',
        startYear: 'August-2016 ',
        endYear: 'June-2018',
        link:''
    },
    
]