export const socialsData = {
    github: 'https://github.com/Manishkoju',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/manish-koju/',
    instagram: 'https://www.instagram.com/koju_manish/',
    codepen: '',
    twitter: 'https://twitter.com/manegiz',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@kojumanish',
    stackOverflow: 'https://stackoverflow.com/users/6165555/manish-koju',
    gitlab: '',
    youtube: 'https://www.youtube.com/manishkoju'
}